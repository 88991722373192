import { createAction, props } from '@ngrx/store';

export const NOTIFICATION_GET_LIST = '[Notification] Get list';
export const NOTIFICATION_GET_LIST_SUCCESS = '[Notification] Get list success';
export const NOTIFICATION_GET_LIST_FAILED = '[Notification] Get list failed';

export const NOTIFICATION_GET_LAST_10 =
  '[Notification] Get last 10 notifications';
export const NOTIFICATION_GET_LAST_10_SUCCESS =
  '[Notification] Get last 10 notifications success';
export const NOTIFICATION_GET_LAST_10_FAILED =
  '[Notification] Get last 10 notifications failed';

export const NOTIFICATION_CONSUME_NOTI = '[Notification] Consume Notifications';
export const NOTIFICATION_READ_NOTI = '[Notification] Read Notification';
