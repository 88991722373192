import { Injectable } from '@angular/core'
import { filter } from 'lodash'
import { hasAccess } from '../../helpers/index'

export interface BadgeItem {
  type: string
  value: string
}
export interface Saperator {
  name: string
  type?: string
}
export interface SubChildren {
  state: string
  name: string
  type?: string
}
export interface ChildrenItems {
  state: string
  name: string
  type?: string
  child?: SubChildren[]
}

export interface Menu {
  state: string
  name: string
  type: string
  icon: string
  badge?: BadgeItem[]
  saperator?: Saperator[]
  children?: ChildrenItems[]
}

const MENUITEMS = [
  {
    state: '/dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'dashboard',
  },
  {
    state: '/case',
    name: 'Cases',
    type: 'link',
    icon: 'work',
  },
  {
    state: '/user-management',
    name: 'Users',
    type: 'link',
    icon: 'person',
  },
  {
    state: '/data-management',
    name: 'Data Management',
    type: 'link',
    icon: 'storage',
  },
  {
    state: '/role-access',
    name: 'Role Access',
    type: 'link',
    icon: 'vpn_key',
  },
  {
    state: '/models',
    name: 'Models',
    type: 'link',
    icon: 'psychology',
  },
  {
    state: '/configuration-menu',
    name: 'Configuration Menus',
    type: 'link',
    icon: 'menu',
  },
  // {
  //   state: '/data-upload',
  //   name: 'Data Upload',
  //   type: 'link',
  //   icon: 'menu',
  // },
  {
    state: '/data-ingestion',
    name: 'Data Ingestion',
    type: 'link',
    icon: 'add_link',
  },
  {
    state: '/rule-based',
    name: 'Rules Based',
    type: 'link',
    icon: 'merge_type',
  },
  {
    state: '/system-settings',
    name: 'System Settings',
    type: 'link',
    icon: 'settings',
    access: ''
  },
  {
    state: '/rule-engine',
    name: 'Rules Engine',
    type: 'link',
    icon: 'merge_type',
  },
]

@Injectable()
export class MenuItems {
  getMenuItem(): Menu[] {
    return MENUITEMS
  }
}
