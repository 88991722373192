<h2 mat-dialog-title>KewDetect Privacy and Policy</h2>
<mat-dialog-content class="mat-typography">
  <!-- <h2> Privacy Policy </h2> -->
<div><p>Welcome to the KewMann privacy policy page, your privacy is important to us so we set out the details of the privacy policy as below (which includes all KewMann’s subsidiaries, related and/or associated companies).<br>

This policy explains how we use and protect your personal data from when you access this site or use our products or services. By using our site and our products and services you consent to KewMann’s use of your data as described below. Should there be any changes to this privacy policy, we will update the information on this page accordingly. </p><br>

<h3> PERSONAL INFORMATION WE COLLECT </h3>
<p>We may collect information either directly (for example, through information provided when you sign up for a newsletter or register to a webinar) or indirectly (for example, through our site’s technology). You are responsible for ensuring that the information you provide us is accurate, complete and not misleading and that such information is kept up to date. <br>

We may collect and process the following information to administer and/or manage your relationship with us including but not limited to the following:</p>
<ul>
  <li>Information provided when filling up forms on our site, which extends to registering to use the site, subscribing to our services, newsletters and alerts, registering for a webinar or requesting a white paper or further information. We may provide explanations on why your personal data is required and how it will be used and you can decide to provide this information or otherwise.</li>
  <li>If you contact us, we may keep a record of that correspondence for our internal compliance purposes.</li>
  <li>Occasionally, you will be requested to complete surveys that we use for research but you are not required to respond to them.</li>
  <li>Any postings, comments or other content that you upload or post on the site.</li>
<li>Information about your computer, including (where available) your IP address, operating system and browser type, for system administration, to filter traffic, to look up user domains and to report on statistics.</li>
<li>Details of your visits to our site and resources you access (including but not limited to, traffic data, location data, weblogs and other communication data).</li>
</ul>
<p>When this information relates to or identifies you, we will treat it as “personal data". </p><br>

<h3> HOW DO WE USE YOUR PERSONAL INFORMATION? </h3>
<p>We use personal data to provide information requested, and other purposes which would be described at the point of collection. For example, we may use your personal data including but not limited to the following purposes:</p>

<ul>
<li>To assess, process and provide products, services and/or facilities to you.</li>
<li>To administer and/or manage your relationship with us.</li>
<li>To respond to your enquiries or complaints on any issues arising from and/or in connection with any transaction with us.</li>
<li>To arrange your participation in any events organised by us, including meetings, seminars, conferences, trainings, promotions or campaigns.</li>
<li>To share any of your personal data with our auditor for our internal audit and reporting purposes.</li>
<li>To share any of your personal data pursuant to any agreement which you have duly entered with us for purposes of seeking legal and/or financial advice.</li>
<li>To share any of your personal data with a third party necessary for the preparation of agreements to be entered by you (if any).</li>
<li>To comply with the applicable legal or regulatory requirements and disclosing any of your personal data under the requirements of any applicable law, regulation, direction, court order, by-law or guideline applicable to us.</li>
<li>To comply with any direction or order from any governmental authority and/or statutory bodies requiring for disclosure of your personal data.</li>
<li>For our storage, hosting back-up of your personal data whether within or outside Malaysia.</li>
<li>To fulfil your requests for white papers, articles, newsletters or other content.</li>
<li>For surveys or research questionnaires.</li>
<li>To personalise and enhance your site experience and your experience in using our products or services.</li>
<li>To contact you for marketing purposes where you have agreed to this.</li>
</ul>
<p>Your IP and browser information will be analysed to determine the effectiveness of our site, services and products; as well as to help us identify ways to improve our site, services and products.</p><br>

<h3> SHARING OF PERSONAL DATA WITH THIRD PARTIES </h3>
<p>Your personal data will not be sold or transferred to any third party without your consent. However, we may disclose your personal data to the following third parties for the performance of one or more of the following purposes:</p>
<ul>
<li>Within the KewMann group of companies including KewMann’s subsidiaries related and/or associated companies and successors in title to us to allow for smooth administration of the site as well as your uninterrupted use of our products and services.</li>
<li>Any party under a duty of confidentiality to which has undertaken to keep your personal data confidential which we have engaged to discharge our obligations to you.</li>
<li>Our auditors, consultants, lawyers, accountants or other financial or professional advisers appointed in connection with our business on a strictly confidential basis, appointed by us to provide services to us.</li>
<li>Any party nominated or appointed by us either solely or jointly with other service providers, for purpose of carrying out our obligations to you.</li>
<li>Data centres and/or servers located within or outside Malaysia for data storage purposes.</li>
<li>Storage facility and records management service providers.</li>
<li>Government agencies, law enforcement agencies, courts, tribunals, regulatory/professional bodies, industry regulators, ministries, and/or statutory agencies or bodies, offices or municipality in any jurisdiction, if required or authorised to do so, to satisfy any applicable law, regulation, order or judgment of a court or tribunal or queries from the relevant authorities.</li>
<li>Our business partners, third party product and/or service providers, suppliers, vendors, distributors, contractors or agents, on a need to know basis, that provide related products and/or services in connection with our business, or discharge or perform one or more of the above purposes and other purposes required to operate and maintain our business.</li>
</ul><br>

<h3>SENSITIVE PERSONAL DATA</h3>
<p>“Sensitive data” is described by European and other data privacy laws to require special treatment, including the circumstances where explicit consent is needed. Generally, we do not collect sensitive personal data through this site. However, if the need arises, which is very rare, we will do so in accordance with the Malaysian Personal Data Protection Act 2010, and to the extent not limited by the relevant European data protection laws (including but not limited to the European Union’s General Data Protection Regulation  (if applicable). If you choose to provide unsolicited sensitive personal data, you consent to us using the data, subject to the applicable law as described in this privacy policy. “Sensitive data” may include personal identity numbers, racial or ethnic origin, political opinions, religious, philosophical or other similar beliefs, membership of a trade union or profession or trade association, physical or mental health, biometric or genetic data, sexual life, or criminal record (including information about suspected criminal activities).</p><br>

<h3>DATA SECURITY</h3>
We take great caution and endeavour to implement appropriate technical, physical, electronic and procedural security methods to maintain the security of personal data collected via our site in compliance with the applicable laws, regulations and industry standards to protect against unauthorised disclosure and/or processing of your personal data. <br>We are committed to implementing updates to our security measures from time to time, where practicable, and ensure the authorised third parties only use your personal data for the purposes set out in this privacy policy page. <br><br>

Whilst we take great caution in implementing security measures to KewMann’s site and your electronic communication with us, it is also your responsibility to understand that the Internet is not a secured medium allowing information and personal data to flow across networks connected to our systems regardless of the security measures and may be accessed and used by people other than those for whom the data is intended.
<br><br>
From time to time, KewMann’s site may present links to and from the sites of our network of partners and advertisers. Should you follow a link to any of these sites, do note that these sites have their own privacy policies and we do not accept any responsibility or liability for these policies or sites. Please check the privacy policies of these sites before you submit any personal data to these sites.<p></p><br>
<h3>PROCESSING OF PERSONAL DATA</h3>
<p>As KewMann has a global presence, personal data collected may be transferred or be accessible throughout our global offices. Any such transfers across our organisation takes place in accordance with the applicable data protection laws and in accordance with our internal privacy policy. Our privacy policy reflects the standards contained in the Malaysian Personal Data Protection Act 2010 and relevant European data protection laws (including the European Union’s General Data Protection Regulation). In this regard, all our global offices will be adhering to the same privacy standards in protecting your personal data regardless of the country in which your personal data is processed by KewMann.<br><br>Further, our information technology storage facilities and servers may be located in a different jurisdiction from where you access our site or utilise our products and services, in such instances, your personal data may be stored in servers located in different jurisdictions. We shall endeavour to ensure that reasonable measures are taken to ensure that all such third parties will not use your personal data save for the purposes authorised and to adequately protect the confidentiality of your personal data.</p><br>
<h3>YOUR RIGHTS</h3>
<p>To the extent permitted by the applicable law, you have the right to know if we hold your personal data and, if we do, you can request for access to that personal data, request for a copy of and require it to be corrected if it is inaccurate. You can do this by communicating with us at the address set out in Clause 12 below.<br><br>In accordance with the Malaysian Personal Data Protection Act 2010, and to the extent not limited by the relevant European data protection laws (including the European Union’s General Data Protection Regulation), we may: (i) charge a fee for processing your request for access or correction to the personal data; and/or (ii) refuse to comply with your request for correction to the personal data and provide you with a basis for our refusal.<br><br>You can choose not to have or discontinue marketing communications sent to you by selecting certain boxes on the relevant correspondences sent by us or by indicating the same by writing to us at the address set out in Clause 12, below.</p><br>

<h3>RETENTION OF PERSONAL DATA</h3>
<p>The personal data provided to us will be retained until the purpose for which your personal data was collected by us continues. In the event the purposes for which your personal data was collected ceases or no longer required for the purpose and then your personal data will be destroyed or anonymised from our records in accordance with our internal policy subject to compliance with our operational, legal, regulatory, tax or accounting requirements.</p><br>
<h3>COOKIES</h3>
<p>Cookies are text files containing small amounts of information downloaded to your computer or mobile device when you visit a site for record-keeping purposes, and is used by us to track your visit to KewMann site.<br><br>
KewMann may from time to time use web beacons or other technologies for similar purposes which are possibly included in marketing e-mail messages or our newsletters to determine whether messages have been opened and links clicked on. Web beacons do not place information on your device, but may work in conjunction with cookies to monitor site activity.<br><br>When you use our site, you agree that we can place cookies on your device as explained above. You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of the KewMann site may become inaccessible or not function properly. For more information about the cookies we use, please see the types which are used by us below. Unless you have modified your browser settings to disable or refuse cookies, our system will issue cookies as soon you visit our site, even if you have previously deleted our cookies.<br><br>Additionally, disabling or refusing cookies will also prevent any web beacons from tracking your user activity on our site. The web beacon will still account for an anonymous visit, but your unique information will not be recorded.
</p><br>
<h3>TYPES OF COOKIES AND WHICH ARE USED BY THE SITE</h3>
<p>Strictly necessary cookies- These cookies are essential to enable you to move around the site and use its features, such as accessing secure areas of the site. Without these cookies’ services, what you have asked for cannot be provided. This category of cookies includes Registered Visitor Cookie where a unique identifier is given to each registered user, and is used to recognise them through their visit and when they return to the site.<br><br>Performance cookies – These cookies collect information about how visitors use a site, for instance, the pages visitors go to most often, and if they get error messages from web pages. These cookies don’t collect information that identifies a visitor. All information these cookies collect is anonymous and is only used to improve how a site works.<br><br>In future, we may use third party social media sites which may log information about you, for example if you click an “Add This” or “Like” button for a social media site while on our site. Activities on these sites are not under our control and you can find security information of the respective social media sites. We recommend you read the terms of use and privacy policy of such sites before using them.</p><br>
<h3>PREVAILING LANGUAGE</h3>
<p>If there is any conflict or discrepancy between the English text of terms and conditions of this Privacy Policy and any translation thereof, the English text shall prevail and supersede any other translation or any other version in any other language.</p><br>

<h3>CONTACT DETAILS</h3>
<p>If you have any questions about this Privacy Policy or would like to make a complaint or data access or correction request in respect of your personal data, please contact us at the contact details below:<br><br>
Attention: “KewMann Personal Data Protection Officer” KewMann Pte. Ltd., 12 Marina Boulevard, Marina Bay Financial Centre Tower 3, #17-01, Singapore 018982. Email: kyl@kewmann.com <br><br>
This privacy policy was updated on July 2020.
</p><br><br>
</div>

</mat-dialog-content>
