import {
  USER_ADD_SUCCESS,
  USER_DELETE_SUCCESS,
  USER_EDIT_SUCCESS,
  USER_GET_INFO_SUCCESS,
  USER_GET_LIST_FAILED,
  USER_GET_LIST_SUCCESS,
  USER_LOAD_FAILED,
  USER_SET_SELECTED,
  USER_CHANGE_PASSWORD_SUCCESS,
  USER_CHANGE_PASSWORD_FAILED,
  USER_CHANGE_PASSWORD,
  USER_GET_LIST,
  USER_GET_ACTIVITIES_TIMELINE,
  USER_GET_ACTIVITIES_TIMELINE_SUCCESS,
  USER_GET_NOTIFICATIONS,
  USER_GET_NOTIFICATIONS_SUCCESS,
  USER_ADD,
  USER_EDIT,
  USER_DELETE,
  USER_RESET_STATUS,
  USER_CHANGE_COVER_SUCCESS,
  USER_CHANGE_COVER_FAILED,
  USER_UPD_PROFILE_SUCCESS,
  USER_UPD_PROFILE_FAILED,
  USER_GET_ACTIVITIES_TIMELINE_FAILED,
  USER_GET_ACTIVITIES_TOP5,
  USER_GET_ACTIVITIES_TOP5_SUCCESS,
  USER_GET_ACTIVITIES_TOP5_FAILED,
  USER_GET_SUCCESS_LOGIN_SUCCESS,
  USER_GET_FAILED_LOGIN_SUCCESS,
  USER_GET_SUCCESS_LOGIN_FAILED,
  USER_GET_FAILED_LOGIN_FAILED,
  USER_GET_ACTIVITIES_SUCCESS,
  USER_GET_ACTIVITIES,
  USER_GET_ALL_ROLES_FAILED,
  USER_GET_ALL_ROLES_SUCCESS,
  USER_EDIT_ROLE_SUCCESS,
  USER_EDIT_ROLE_FAILED,
  USER_SEARCH,
  USER_SEARCH_SUCCESS,
  USER_SEARCH_FAILED,
  USER_UPD_PROFILE,
  USER_DELETE_FAILED,
  USER_EDIT_ROLE,
  USER_GET_NOTIFICATIONS_FAILED,
  USER_UPDATE_STATUS, USER_UPDATE_STATUS_SUCCESS,
} from "./user.actions";
import User from "../models/User";
import ChangeCoverEntity from "../models/ChangeCoverEntity";
import ActivityTimeline from "../models/ActivityTimeline";
import Activity from "../models/Activity";
import UserActivitiesFilter from "../models/UserActivitiesFilter";
import Role from "../models/Role";
import UserNotificationFilter from "../models/UserNotificationFilter";
import Notification from "../models/Notification";
import {HttpErrorResponse} from "@angular/common/http";
export interface State {
  list: any[];
  filters: {
    status: string;
  };
  selected: User;
  status: {
    loadSuccess: any;
    errMessage: string;
  };
  deleteStatus: {
    success: boolean,
    err: string
  }
  updateProfileStatus: {
    success: boolean;
    err: string;
  };
  changeCoverStatus: {
    success: boolean;
    err: string;
    entity: ChangeCoverEntity;
  };
  pageOptions: {
    currentPage: number;
    limit: number;
    total: number;
  };
  activities: ActivityTimeline[];
  activitiesFilter: {
    month: string;
    year: string;
  };
  activitiesTop5Status: {
    success: boolean;
    err: string;
    activities: Activity[];
  };
  notificationsTop5: {
    notifications: Notification[];
    first: boolean;
    status: {
      success: boolean;
      err: string;
    }
  };
  successLogin: {
    success: boolean;
    err: string;
    total: number;
    details: Activity[];
  };
  failedLogin: {
    success: boolean;
    err: string;
    total: number;
    details: Activity[];
  };
  activitiesPagingStatus: {
    success: boolean;
    err: string;
    activities: Activity[];
    paging: UserActivitiesFilter;
  };
  notificationPagingStatus: {
    success: boolean;
    err: string;
    notifications: Notification[];
    paging: UserNotificationFilter;
  };
  editRole: {
    status: {
      success: boolean;
      err: string;
    };
    editStatus: {
      success: boolean;
      err: string;
    };
    roles: Role[];
  };
  globalMessage: string;
}

export const initState: State = {
  list: [],
  filters: {
    status: "",
  },
  selected: undefined,
  status: {
    loadSuccess: undefined,
    errMessage: "",
  },
  deleteStatus: {
    success: undefined,
    err: ""
  },
  updateProfileStatus: {
    success: undefined,
    err: null,
  },
  changeCoverStatus: {
    success: undefined,
    err: null,
    entity: undefined,
  },
  pageOptions: {
    currentPage: 1,
    limit: 10,
    total: null,
  },
  activities: [],
  activitiesFilter: {
    month: null,
    year: null,
  },
  activitiesTop5Status: {
    success: undefined,
    err: null,
    activities: [],
  },
  notificationsTop5: {
    notifications: [],
    first: false,
    status: {
      success: undefined,
      err: null
    }
  },
  successLogin: {
    success: undefined,
    err: null,
    total: 0,
    details: [],
  },
  failedLogin: {
    success: undefined,
    err: null,
    total: 0,
    details: [],
  },
  activitiesPagingStatus: {
    success: undefined,
    err: null,
    activities: [],
    paging: null,
  },
  notificationPagingStatus: {
    success: undefined,
    err: null,
    notifications: [],
    paging: null,
  },
  editRole: {
    status: {
      success: undefined,
      err: null,
    },
    editStatus: {
      success: undefined,
      err: null,
    },
    roles: [],
  },
  globalMessage: null,
};

export function UserReducers(state = initState, action: any): State {

  // Global handling error with HTTP status 400
  state = {
    ...state,
    globalMessage: null
  };
  if (action.payload && action.payload instanceof HttpErrorResponse) {
    const { status, error }: HttpErrorResponse = action.payload
    state = {
      ...state,
      globalMessage: status === 400 ? error.message : null
    }
  }

  switch (action.type) {
    case USER_SEARCH:
    case USER_GET_LIST: {
      return {
        ...state,
        list: [],
        status: {
          loadSuccess: undefined,
          errMessage: "",
        },
      };
    }
    case USER_SEARCH_SUCCESS: {
      return {
        ...state,
        list: action.payload.list,
        status: {
          errMessage: "",
          loadSuccess: true,
        },
      };
    }
    case USER_GET_LIST_SUCCESS: {
      const { list, pageOptions, filters } = action.payload;
      return {
        ...state,
        list,
        pageOptions,
        filters,
        selected: undefined,
        status: {
          loadSuccess: true,
          errMessage: "",
        },
      };
    }
    case USER_SEARCH_FAILED:
    case USER_GET_LIST_FAILED: {
      return {
        ...state,
        list: [],
        status: {
          loadSuccess: false,
          errMessage: action.payload,
        },
      };
    }
    case USER_SET_SELECTED: {
      return {
        ...state,
        selected: action.payload,
        deleteStatus: {
          success: undefined,
          err: null
        }
      };
    }
    case USER_ADD:
    case USER_EDIT:
    {
      return {
        ...state,
        status: {
          loadSuccess: undefined,
          errMessage: "",
        },
      };
    }
    case USER_EDIT_SUCCESS: {
      return {
        ...state,
        selected: undefined,
        status: {
          errMessage: "",
          loadSuccess: true,
        },
      };
    }
    case USER_ADD_SUCCESS: {
      return {
        ...state,
        selected: undefined,
        status: {
          errMessage: "",
          loadSuccess: true,
        },
      };
    }
    case USER_DELETE: {
      return {
        ...state,
        deleteStatus: {
          success: undefined,
          err: ""
        }
      }
    }
    case USER_DELETE_SUCCESS: {
      return {
        ...state,
        selected: undefined,
        deleteStatus: {
          err: "",
          success: true,
        },
      };
    }
    case USER_DELETE_FAILED: {
      return {
        ...state,
        deleteStatus: {
          err: action.payload.error.message,
          success: false,
        },
      };
    }

    case USER_GET_INFO_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          loadSuccess: undefined,
        },
        selected: action.payload,
      };
    }
    case USER_LOAD_FAILED:  {
      return {
        ...state,
        status: {
          ...state.status,
          loadSuccess: false,
        },
        selected: null,
      };
    }
    case USER_UPD_PROFILE: {
      return {
        ...state,
        updateProfileStatus: {
          success: undefined,
          err: null,
        },
      };
    }
    case USER_UPD_PROFILE_SUCCESS: {
      return {
        ...state,
        selected: {
          ...state.selected,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          phone_number: action.payload.phone_number,
        },
        updateProfileStatus: {
          success: true,
          err: null,
        },
      };
    }
    case USER_UPD_PROFILE_FAILED: {
      return {
        ...state,
        updateProfileStatus: {
          success: false,
          err: action.payload,
        },
      };
    }
    case USER_CHANGE_COVER_SUCCESS: {
      return {
        ...state,
        changeCoverStatus: {
          success: true,
          err: null,
          entity: action.payload.entity,
        },
      };
    }
    case USER_CHANGE_COVER_FAILED: {
      return {
        ...state,
        changeCoverStatus: {
          success: false,
          err: action.payload,
          entity: undefined,
        },
      };
    }
    case USER_CHANGE_PASSWORD: {
      return {
        ...state,
        status: {
          loadSuccess: undefined,
          errMessage: "",
        },
      };
    }
    case USER_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        status: {
          ...state.status,
          loadSuccess: true,
          errMessage: "",
        },
      };
    }
    case USER_CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        status: {
          loadSuccess: false,
          errMessage: action.payload,
        },
      };
    }
    case USER_GET_ACTIVITIES_TIMELINE: {
      return {
        ...state,
        status: {
          loadSuccess: undefined,
          errMessage: "",
        },
        activities: [],
      };
    }
    case USER_GET_ACTIVITIES_TIMELINE_SUCCESS: {
      return {
        ...state,
        activities: action.payload,
      };
    }
    case USER_GET_ACTIVITIES_TIMELINE_FAILED: {
      return {
        ...state,
        status: {
          loadSuccess: false,
          errMessage: action.payload,
        },
        activities: [],
      };
    }
    case USER_GET_ACTIVITIES_TOP5: {
      return {
        ...state,
        activitiesTop5Status: {
          success: undefined,
          err: null,
          activities: [],
        },
      };
    }
    case USER_GET_ACTIVITIES_TOP5_SUCCESS: {
      return {
        ...state,
        activitiesTop5Status: {
          success: true,
          err: null,
          activities: action.payload.map(
            (a: any) => new Activity(a.action, a.start_time)
          ),
        },
      };
    }
    case USER_GET_ACTIVITIES_TOP5_FAILED: {
      return {
        ...state,
        activitiesTop5Status: {
          success: false,
          err: action.payload,
          activities: [],
        },
      };
    }

    case USER_GET_NOTIFICATIONS: {
      return {
        ...state,
        notificationPagingStatus: {
          success: undefined,
          err: null,
          notifications: [],
          paging: action.payload.paging
        }
      };
    }
    case USER_GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        notificationPagingStatus: {
          success: true,
          err: null,
          notifications: action.payload.notifications.map(
            (noti: any) => new Notification(noti.id, noti.title, noti.message, noti.type, noti.status, noti.date, noti.timestamp, noti.icon)
          ),
          paging: {...action.payload.paging}
        }
      };
    }
    case USER_GET_NOTIFICATIONS_FAILED: {
      return {
        ...state,
        notificationPagingStatus: {
          success: false,
          err: action.payload,
          notifications: [...state.notificationPagingStatus.notifications],
          paging: {...state.notificationPagingStatus.paging}
        }
      };
    }
    case USER_RESET_STATUS: {
      return {
        ...state,
        status: {
          errMessage: "",
          loadSuccess: undefined,
        },
      };
    }

    case USER_GET_SUCCESS_LOGIN_SUCCESS: {
      return {
        ...state,
        successLogin: {
          ...action.payload,
          success: false,
          err: null,
        },
      };
    }

    case USER_GET_FAILED_LOGIN_SUCCESS: {
      return {
        ...state,
        failedLogin: {
          ...action.payload,
          success: false,
          err: null,
        },
      };
    }

    case USER_GET_SUCCESS_LOGIN_FAILED: {
      return {
        ...state,
        successLogin: {
          success: false,
          err: action.payload,
          total: 0,
          details: [],
        },
        activities: [],
      };
    }
    case USER_GET_FAILED_LOGIN_FAILED: {
      return {
        ...state,
        failedLogin: {
          success: false,
          err: action.payload,
          total: 0,
          details: [],
        },
        activities: [],
      };
    }
    case USER_GET_ACTIVITIES: {
      return {
        ...state,
        activitiesPagingStatus: {
          paging: null,
          activities: [],
          success: undefined,
          err: null,
        },
      };
    }
    case USER_GET_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        activitiesPagingStatus: {
          paging: action.payload.paging,
          activities: action.payload.auditLogs.map(
            (a: any) => new Activity(a.action, a.start_time)
          ),
          success: true,
          err: null,
        },
      };
    }
    case USER_GET_ALL_ROLES_SUCCESS: {
      return {
        ...state,
        editRole: {
          ...state.editRole,
          status: {
            success: true,
            err: null,
          },
          roles: action.payload.roles,
        },
      };
    }
    case USER_GET_ALL_ROLES_FAILED: {
      return {
        ...state,
        editRole: {
          ...state.editRole,
          status: {
            success: false,
            err: action.payload,
          },
        },
      };
    }
    case USER_EDIT_ROLE: {
      return {
        ...state,
        editRole: {
          ...state.editRole,
          editStatus: {
            success: undefined,
            err: null
          }
        }
      }
    }
    case USER_EDIT_ROLE_SUCCESS: {
      return {
        ...state,
        editRole: {
          ...state.editRole,
          editStatus: {
            success: true,
            err: null,
          },
        },
      };
    }
    case USER_EDIT_ROLE_FAILED: {
      return {
        ...state,
        editRole: {
          ...state.editRole,
          editStatus: {
            success: false,
            err: action.payload,
          },
        },
      };
    }
    case USER_UPDATE_STATUS: {
      return {
        ...state,
        status: {
          loadSuccess: undefined,
          errMessage: "",
        },
      };
    }
    case USER_UPDATE_STATUS_SUCCESS: {
      return {
        ...state,
        status: {
          loadSuccess: true,
          errMessage: "",
        }
      }
    }
    default: {
      return state;
    }
  }
}
