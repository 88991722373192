import { Routes } from '@angular/router'
import { FullComponent } from './layouts/full/full.component'
import { AuthGuard } from './middlewares/auth-guard'
import { ErrorComponent } from './static-pages/error/error.component'
import { ResetPasswordExpiredComponent } from './static-pages/reset-password-expired/reset-password-expired.component'

export const AppRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: { breadcrumb: 'Dashboard' },
      },
      {
        path: 'case',
        loadChildren: () =>
          import('./case/case.module').then((m) => m.CaseModule),
        data: { breadcrumb: 'Cases' },
      },
      {
        path: 'evidence',
        loadChildren: () =>
          import('./evidence/evidence.module').then((m) => m.EvidenceModule),
        data: { breadcrumb: 'Overview Evidence' },
      },
      {
        path: 'network-analysis',
        loadChildren: () =>
          import('./case-lists/case-lists.module').then(
            (m) => m.CaseListsModule
          ),
        data: { breadcrumb: 'Network Analysis' },
      },
      {
        path: 'user-management',
        loadChildren: () =>
          import('./user-management/user-management.module').then(
            (m) => m.UserManagementModule
          ),
        data: { breadcrumb: 'Users' },
      },
      {
        path: 'data-management',
        loadChildren: () =>
          import('./data-management/data-management.module').then(
            (m) => m.DataManagementModule
          ),
      },
      {
        path: 'role-access',
        loadChildren: () =>
          import('./role-access/role-access.module').then(
            (m) => m.RoleAccessModule
          ),
        data: { breadcrumb: 'Role Access' },
      },
      {
        path: 'models',
        loadChildren: () =>
          import('./cognitive-ai/cognitive-ai.module').then(
            (m) => m.CognitiveAiModule
          ),
        data: { breadcrumb: 'Models' },
      },
      {
        path: 'configuration-menu',
        loadChildren: () =>
          import('./configuration-menu/configuration-menu.module').then(
            (m) => m.ConfigurationMenuModule
          ),
        data: { breadcrumb: 'Configuration Menu' },
      },
      {
        path: 'data-upload',
        loadChildren: () =>
          import('./data-upload/data-upload.module').then(
            (m) => m.DataUploadModule
          ),
        data: { breadcrumb: 'Data Upload' },
      },
      {
        path: 'data-ingestion',
        loadChildren: () =>
          import('./data-ingestion/data-ingestion.module').then(
            (m) => m.DataIngestionModule
          ),
        data: { breadcrumb: 'Data Ingestion' },
      },
      {
        path: 'rule-based',
        loadChildren: () =>
          import('./rule-based/rule-based.module').then(
            (m) => m.RuleBasedModule
          ),
        data: { breadcrumb: 'Rules Based' },
      },
      {
        path: 'system-settings',
        loadChildren:
            () => import('./system-settings/system-settings.module').then(m => m.SystemSettingsModule)
      },
      {
        path: 'rule-engine',
        loadChildren: () =>
          import('./rule-engine/rule-engine.module').then(
            (m) => m.RuleEngineModule
          ),
        data: { breadcrumb: 'Rule Engine' },
      },
    ],
  },
  {
    path: 'authentication',
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'reset-password-expired',
    component: ResetPasswordExpiredComponent,
  },
  {
    path: '404',
    component: ErrorComponent,
  },
  {
    path: '**',
    redirectTo: '404',
  },
]
