<h1 mat-dialog-title>Confirmation Dialog</h1>
<div mat-dialog-content>
  <div fxLayout="row wrap">
    <div class="p-10" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
      {{ confirmMessage }}
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button tabindex="2" [mat-dialog-close]="true">
    Yes
  </button>
  <button mat-button (click)="onNoClick()" tabindex="-1">
    No
  </button>
</div>
