export const USER_GET_LIST = "[User] Get list";
export const USER_GET_LIST_SUCCESS = "[User] Get list success";
export const USER_GET_LIST_FAILED = "[User] Get list failed";

export const USER_SET_SELECTED = "[User] Set selected user";

export const USER_EDIT = "[User] Edit user";
export const USER_EDIT_SUCCESS = "[User] Edit user success";

export const USER_ADD = "[User] Add user";
export const USER_ADD_SUCCESS = "[User] Add user success";

export const USER_DELETE = "[User] Delete user";
export const USER_DELETE_SUCCESS = "[User] Delete user success";
export const USER_DELETE_FAILED = "[User] Delete user failed";

export const USER_LOAD_FAILED = "[User] Load failed";

export const USER_GET_INFO = "[User] Get user info";
export const USER_GET_INFO_SUCCESS = "[User] Get user info success";

export const USER_UPDATE_STATUS = "[User] Update user status";
export const USER_UPDATE_STATUS_SUCCESS = "[User] Update user status success";
export const USER_UPDATE_STATUS_FAILED = "[User] Update user status failed";

export const USER_CHANGE_PASSWORD = "[User] Change password";
export const USER_CHANGE_PASSWORD_SUCCESS = "[User] Change password success";
export const USER_CHANGE_PASSWORD_FAILED = "[User] Change password failed";

export const USER_CHANGE_COVER = "[User] Change cover";
export const USER_CHANGE_COVER_SUCCESS = "[User] Change cover success";
export const USER_CHANGE_COVER_FAILED = "[User] Change cover failed";

export const USER_GET_ACTIVITIES_TIMELINE =
  "[User] Get list activities timeline";
export const USER_GET_ACTIVITIES_TIMELINE_SUCCESS =
  "[User] Get list activities timeline success";
export const USER_GET_ACTIVITIES_TIMELINE_FAILED =
  "[User] Get list activities timeline failed";

export const USER_GET_ACTIVITIES = "[User] Get list activities";
export const USER_GET_ACTIVITIES_SUCCESS = "[User] Get list activities success";
export const USER_GET_ACTIVITIES_FAILED = "[User] Get list activities failed";

export const USER_GET_NOTIFICATIONS = "[User] Get list notifications";
export const USER_GET_NOTIFICATIONS_SUCCESS =
  "[User] Get list notifications success";
export const USER_GET_NOTIFICATIONS_FAILED =
  "[User] Get list notifications failed";

export const USER_GET_ACTIVITIES_TOP5 = "[User] Get list activities top 5";
export const USER_GET_ACTIVITIES_TOP5_SUCCESS =
  "[User] Get list activities top 5 success";
export const USER_GET_ACTIVITIES_TOP5_FAILED =
  "[User] Get list activities top 5 failed";

export const USER_GET_NOTIFICATIONS_TOP5 =
  "[User] Get list notifications top 5";
export const USER_GET_NOTIFICATIONS_TOP5_SUCCESS =
  "[User] Get list notifications top 5 success";
export const USER_GET_NOTIFICATIONS_TOP5_FAILED =
  "[User] Get list notifications top 5 failed";

export const USER_UPD_NOTIFICATIONS =
  "[User] Mark all user notifications as read";
export const USER_UPD_NOTIFICATIONS_SUCCESS =
  "[User] Mark all user notifications as read success";
export const USER_UPD_NOTIFICATIONS_FAILED =
  "[User] Mark all user notifications as read failed";

export const USER_RESET_STATUS = "[User] Reset status";

export const USER_UPD_PROFILE = "[User] Update Profile";
export const USER_UPD_PROFILE_SUCCESS = "[User] Update Profile Success";
export const USER_UPD_PROFILE_FAILED = "[User] Update Profile Failed";

export const USER_GET_SUCCESS_LOGIN = "[User] Get Success Login";
export const USER_GET_SUCCESS_LOGIN_SUCCESS =
  "[User] Get Success Login Success";
export const USER_GET_SUCCESS_LOGIN_FAILED = "[User] Get Success Login Failed";

export const USER_GET_FAILED_LOGIN = "[User] Get Failed Login";
export const USER_GET_FAILED_LOGIN_SUCCESS = "[User] Get Failed Login Success";
export const USER_GET_FAILED_LOGIN_FAILED = "[User] Get Failed Login Failed";

export const USER_GET_ALL_ROLES = "[User] Get All Roles";
export const USER_GET_ALL_ROLES_SUCCESS = "[User] Get All Roles Success";
export const USER_GET_ALL_ROLES_FAILED = "[User] Get All Roles Failed";

export const USER_EDIT_ROLE = "[User] Edit Role";
export const USER_EDIT_ROLE_SUCCESS = "[User] Edit Role Success";
export const USER_EDIT_ROLE_FAILED = "[User] Edit Role Failed";

export const USER_SEARCH = "[User] Search";
export const USER_SEARCH_SUCCESS = "[User] Search success";
export const USER_SEARCH_FAILED = "[User] Search failed";
