<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div class="sidebar-logo">
  <a href="/">
    <img
      src="assets/images/logo-icon2.png"
      class="full-logo"
      alt="homepage"
    />
    <img
      src="assets/images/mini-logo.png"
      class="mini-logo"
      alt="homepage"
    />
  </a>
  <button mat-icon-button fxHide fxShow.gt-sm class="menu-button" (click)="triggerSidebar()">
    <mat-icon>menu</mat-icon>
  </button>
</div>
<div class="sidebar-title">
  <span class="font-12">MENU</span>
</div>

<mat-nav-list appAccordion class="m-b-15 side-list">
  <mat-list-item
    appAccordionLink
    *ngFor="let menuItem of menuItems.getMenuItem()"
    routerLinkActive="selected"
    [routerLinkActiveOptions]="{ exact: true }"
    group="{{ menuItem.state }}"
  >
    <a
      class=""
      appAccordionToggle
      [routerLink]="[menuItem.state]"
      *ngIf="menuItem.type === 'link'"
      [matTooltip]="Minisidebar ? menuItem.name : null"
      [matTooltipPosition]="positionOptions"
    >
      <mat-icon>{{ menuItem.icon }}</mat-icon>
      <span>{{ menuItem.name }}</span>
      <span fxFlex></span>
      <span
        class="label label-{{ badge.type }}"
        *ngFor="let badge of menuItem.badge"
      >{{ badge.value }}</span
      >
    </a>
    <a
      class=""
      appAccordionToggle
      href="{{ menuItem.state }}"
      *ngIf="menuItem.type === 'extLink'"
    >
      <mat-icon>{{ menuItem.icon }}</mat-icon>
      <span>{{ menuItem.name }}</span>
      <span fxFlex></span>
      <span
        class="label label-{{ badge.type }}"
        *ngFor="let badge of menuItem.badge"
      >{{ badge.value }}</span
      >
    </a>
    <a
      class=""
      appAccordionToggle
      href="{{ menuItem.state }}"
      target="_blank"
      *ngIf="menuItem.type === 'extTabLink'"
    >
      <mat-icon>{{ menuItem.icon }}</mat-icon>
      <span>{{ menuItem.name }}</span>
      <span fxFlex></span>
      <span
        class="label label-{{ badge.type }}"
        *ngFor="let badge of menuItem.badge"
      >{{ badge.value }}</span
      >
    </a>

    <a
      class=""
      appAccordionToggle
      href="javascript:;"
      *ngIf="menuItem.type === 'sub'"
    >
      <mat-icon>{{ menuItem.icon }}</mat-icon>
      <span>{{ menuItem.name }}</span>
      <span fxFlex></span>
      <span
        class="label label-{{ badge.type }}"
        *ngFor="let badge of menuItem.badge"
      >{{ badge.value }}</span
      >
      <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
    </a>
    <mat-nav-list class="sub-item" *ngIf="menuItem.type === 'sub'">
      <mat-list-item
        *ngFor="let childitem of menuItem.children"
        routerLinkActive="selected"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <a
          [routerLink]="['/', menuItem.state, childitem.state]"
          *ngIf="childitem.type === 'link'"
          class="relative"
          routerLinkActive="selected"
          (click)="itemSelect[i] = j"
        >{{ childitem.name }}</a
        >
      </mat-list-item>
      <mat-list-item *ngFor="let childItem of menuItem.children; let j = index">
        <a
          class=""
          href="javascript: void(0);"
          *ngIf="childItem.type === 'subchild'"
          (click)="itemSelect[i] = j"
          [ngClass]="j == itemSelect[i] ? 'selected' : ''"
        >
          <span>{{ childItem.name }}</span>
          <span fxFlex></span>
          <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>
        <mat-nav-list
          class="child-sub-item"
          *ngIf="childItem.type === 'subchild'"
        >
          <mat-list-item
            *ngFor="let child of childItem.child"
            routerLinkActive="selected"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <a
              [routerLink]="['/', menuItem.state, childItem.state, child.state]"
              routerLinkActive="selected"
            >{{ child.name }}</a
            >
          </mat-list-item>
        </mat-nav-list>
      </mat-list-item>
    </mat-nav-list>

    <div class="saperator text-muted" *ngIf="menuItem.type === 'saperator'">
      <span>{{ menuItem.name }}</span>
    </div>
  </mat-list-item>
</mat-nav-list>

<div class="sidebar-footer">
  <div class="sidebar-title">
    <span class="font-12">PROFILE</span>
  </div>
  <div class="user-profile" *ngIf="user$ | async" fxLayout="row">
    <a (click)="profileAction()" class="profile-link">
      <div class="profile-img">
        <img [src]="(user$ | async).logo" alt="user" *ngIf="shortUsername == ''">
        <div class="profile-avatar" *ngIf="shortUsername != ''">{{ shortUsername }}</div>
      </div>
    </a>
    <div class="profile-text" fxLayoutAlign="start center">
      <span *ngIf="(user$ | async)" class="">{{(user$ | async).first_name}} {{(user$ | async).last_name}}</span>
    </div>
  </div>
  <div class="p-20 m-t-10 logout-button">
    <button mat-stroked-button (click)="logoutAction()"><mat-icon aria-hidden="false" aria-label="logout">logout</mat-icon><span class="logout-text"> Log Out</span></button>
  </div>
</div>
