import { Injectable } from '@angular/core';
import { VERSION } from '../../environments/version';

const versionPersistKey = Symbol('KewDetect-Version');

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor() {}

  public load(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const currentVersion = localStorage.getItem(versionPersistKey.toString());
      if (VERSION.hash !== currentVersion) {
        localStorage.clear();
        localStorage.setItem(versionPersistKey.toString(), VERSION.hash);
      }
      resolve(true);
    });
  }
}
