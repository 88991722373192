import * as actions from './notification.actions';

export interface State {
  notiTop10: {
    notis: Notification[];
    first: boolean;
    status: {
      success: boolean;
      err: string;
    };
  };
}

export const initState: State = {
  notiTop10: {
    notis: [],
    first: false,
    status: {
      success: undefined,
      err: '',
    },
  },
};

export function NotificationReducers(state = initState, action: any): State {
  switch (action.type) {
    case actions.NOTIFICATION_GET_LAST_10: {
      return {
        notiTop10: {
          notis: [...state.notiTop10.notis],
          first: false,
          status: {
            success: undefined,
            err: '',
          },
        },
      };
    }
    case actions.NOTIFICATION_GET_LAST_10_SUCCESS: {
      return {
        notiTop10: {
          notis: [...action.payload.list],
          first: true,
          status: {
            success: true,
            err: '',
          },
        },
      };
    }
    case actions.NOTIFICATION_GET_LAST_10_FAILED: {
      return {
        notiTop10: {
          notis: [...state.notiTop10.notis],
          first: false,
          status: {
            success: false,
            err: action.payload,
          },
        },
      };
    }
    //   case actions.ROLE_UPD_ROLE_LIST_PAGING: {
    //     return {
    //       ...state,
    //       pageOptions: {
    //         ...action.payload,
    //       },
    //     };
    //   }
    //   case actions.ROLE_DELETE_ROLE: {
    //     return {
    //       ...state,
    //       deleteStatus: {
    //         success: undefined,
    //         errMessage: "",
    //       },
    //     };
    //   }
    //   case actions.ROLE_GET_LIST: {
    //     return {
    //       ...state,
    //       //roles: [],
    //       status: {
    //         loadSuccess: undefined,
    //         errMessage: "",
    //       },
    //     };
    //   }
    //   case actions.ROLE_ADD_ROLE:
    //   case actions.ROLE_UPD_ROLE: {
    //     return {
    //       ...state,
    //       updStatus: {
    //         success: undefined,
    //         errMessage: "",
    //       },
    //     };
    //   }
    //   case actions.ROLE_GET_LIST_SUCCESS: {
    //     return {
    //       ...state,
    //       roles: action.payload.list.map((r: Role) => {
    //         return {
    //           ...r,
    //           ...{ _object_id: r.role_id },
    //         };
    //       }),
    //       pageOptions: {
    //         currentPage: action.payload.pageOptions.currentPage,
    //         limit: action.payload.pageOptions.limit,
    //         total: action.payload.pageOptions.total,
    //         keyword: action.payload.pageOptions.keyword,
    //       },
    //       selectedRole: undefined,
    //       status: {
    //         loadSuccess: true,
    //         errMessage: "",
    //       },
    //     };
    //   }
    //   case actions.ROLE_GET_LIST_RULES: {
    //     return {
    //       ...state,
    //       rules: [],
    //     };
    //   }
    //   case actions.ROLE_GET_LIST_RULES_SUCCESS: {
    //     return {
    //       ...state,
    //       rules: action.payload.list,
    //     };
    //   }
    //   case actions.ROLE_GET_ROLE_DETAILS: {
    //     return {
    //       ...state,
    //       selectedRole: undefined,
    //     };
    //   }
    //   case actions.ROLE_GET_ROLE_DETAILS_SUCCESS: {
    //     return {
    //       ...state,
    //       selectedRole: action.payload.selectedRole,
    //     };
    //   }
    //   case actions.ROLE_DELETE_ROLE_SUCCESS: {
    //     return {
    //       ...state,
    //       deleteStatus: {
    //         success: true,
    //         errMessage: "",
    //       },
    //     };
    //   }
    //   case actions.ROLE_ADD_ROLE_SUCCESS:
    //   case actions.ROLE_UPD_ROLE_SUCCESS: {
    //     return {
    //       ...state,
    //       updStatus: {
    //         success: true,
    //         errMessage: "",
    //       },
    //     };
    //   }
    //   case actions.ROLE_ADD_ROLE_FAILED:
    //   case actions.ROLE_UPD_ROLE_FAILED: {
    //     return {
    //       ...state,
    //       updStatus: {
    //         success: false,
    //         errMessage: action.payload.error.message,
    //       },
    //     };
    //   }
    default: {
      return state;
    }
  }
}
