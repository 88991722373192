<h2 mat-dialog-title>KewDetect Terms and Conditions</h2>
<mat-dialog-content class="mat-typography mat-dialog-content-min-height">
  <!-- <h2><strong>Terms &amp; Conditions</strong></h2> -->
<p><strong>PLEASE READ THESE CUSTOMER TERMS OF SERVICE CAREFULLY.</strong></p>
<p>Our Customer Terms of Service is a contract that governs our customers' use of the KewMann services. It consists of the following documents:</p>
<p><a href="https://legal.hubspot.com/terms-of-service#Master-Terms"><strong>Master Terms</strong></a>: These contain the core legal and commercial terms that apply to your subscription</p>
<p><a href="https://legal.hubspot.com/product-specific-terms"><strong>Product Specific Terms</strong></a>: These include any additional terms that apply to your use of each of our product offerings, our consulting and other services and third party services.</p>
<p><a href="https://legal.hubspot.com/jurisdiction-specific-terms"><strong>Jurisdiction Specific Terms</strong></a>: Depending on your location, some of these jurisdiction specific terms will apply to you. They&rsquo;ll also explain which KewMann entity you&rsquo;re contracting with and which laws will govern our relationship.</p>
<p><a href="https://legal.hubspot.com/dpa"><strong>Data Processing Agreement (DPA)</strong></a><strong>:</strong>&nbsp;This explains how we process your data and includes the EU Standard Contractual Clauses.</p>
<p><a href="https://legal.hubspot.com/acceptable-use"><strong>Acceptable Use Policy (AUP)</strong></a>: This is the definitive rulebook setting out what you can and can&rsquo;t do while using our products and services.</p>
<p><a href="https://legal.hubspot.com/product-privacy-policy"><strong>Product Privacy Policy</strong></a>: This policy describes how we collect, receive, use, store, share, transfer, and process your Personal Data in connection with your use of the Subscription Service. It also describes your choices regarding use, as well as your rights of access to and correction of your Personal Data.</p>
<p><strong>Your Order Form</strong>&nbsp;is the KewMann-approved form created following your purchase of one of our products or services through our online payment process or via in-app purchase. It contains all of the details about your purchase, including your subscription term, products purchased and your fees. You&rsquo;ll find your Order Form(s) in the Documents section of your KewMann portal.</p>
<p>We&rsquo;ve aimed to keep these documents as readable as possible, but in some cases for legal reasons, some of the language is necessarily &ldquo;legalese&rdquo;. By using the Subscription Service or receiving the Consulting Services, you are agreeing to these terms.&nbsp;</p>
<p>We update these terms from time to time. If you have an active KewMann subscription, we will let you know when we update the terms via in-app notification or by email (if you subscribe to receive email updates). You can find archived versions of the Master Terms, Product Specific Terms, Jurisdiction Specific Terms and DPA&nbsp;<a href="https://legal.hubspot.com/legal-stuff/archive?hsLang=fr"><strong>here</strong></a>.</p>
<p>&nbsp;</p>
<h3><strong>Master Terms</strong></h3>
<p>Last modified October 1, 2020</p>
<ul>
  <li>1.&nbsp;<a href="https://legal.hubspot.com/terms-of-service#Definitions"><strong>Definitions</strong></a></li>
  <li>2.&nbsp;<a href="https://legal.hubspot.com/terms-of-service#Use-of-Services"><strong>Use of Services&nbsp;</strong></a></li>
  <li>3.&nbsp;<a href="https://legal.hubspot.com/terms-of-service#Fees"><strong>Fees</strong></a></li>
  <li>4.&nbsp;<a href="https://legal.hubspot.com/terms-of-service#Term-and-Termination"><strong>Term &amp; Termination</strong></a></li>
  <li>5.&nbsp;<a href="https://legal.hubspot.com/terms-of-service#Customer-Data"><strong>Customer Data</strong></a></li>
  <li>6.&nbsp;<a href="https://legal.hubspot.com/terms-of-service#Intellectual-Property"><strong>Intellectual Property</strong></a></li>
  <li>7.&nbsp;<a href="https://legal.hubspot.com/terms-of-service#Confidentiality"><strong>Confidentiality</strong></a></li>
  <li>8.&nbsp;<a href="https://legal.hubspot.com/terms-of-service#Publicity"><strong>Publicity</strong></a></li>
  <li>9.&nbsp;<a href="https://legal.hubspot.com/terms-of-service#Indemnification"><strong>Indemnification</strong></a></li>
  <li>10.&nbsp;<a href="https://legal.hubspot.com/terms-of-service#Disclaimers-Limitation-of-Liability"><strong>Disclaimers and Liability</strong></a></li>
  <li>11.&nbsp;<a href="https://legal.hubspot.com/terms-of-service#Miscellaneous"><strong>Miscellaneous</strong></a></li>
</ul>
<p><a href="https://legal.hubspot.com/terms-of-service#Appendix-1-Additional-Coverage-Terms"><strong>Appendix 1:&nbsp; Additional Coverage Terms</strong></a></p>
<p><a href="https://legal.hubspot.com/terms-of-service#Appendix-2-US-Government-Terms"><strong>Appendix 2:&nbsp; U.S. Government Customer Additional Terms</strong></a></p>
<p>&nbsp;</p>
<ol>
  <li><strong> DEFINITIONS</strong></li>
</ol>
<p>&ldquo;Add-Ons&rdquo; means additional product enhancements (including limit increases, capacity packs, and other add-ons) that are made available for purchase and are listed in the &lsquo;Add-Ons and Technical Limits&rsquo; section of our Products and Services Catalog.</p>
<p>"Affiliate" means any entity which directly or indirectly controls, is controlled by, or is under common control with a party to this Agreement. For purposes of this definition, control means direct or indirect ownership or control of more than 50% of the voting interests of the subject entity.</p>
<p>"Agreement" or &ldquo;Customer Terms of Service&rdquo; means these Master Terms and all materials referred or linked to in here.&nbsp;</p>
<p>"Billing Period" means the period for which you agree to prepay fees under an Order Form. This may be the same length as the Subscription Term specified in the Order Form, or it may be shorter. For example, if you subscribe to the Subscription Service for a one (1) year Subscription Term, with a twelve (12) month upfront payment, the Billing Period will be twelve (12) months.</p>
<p>&ldquo;Confidential Information&rdquo; means all confidential information disclosed by a party ("Disclosing Party") to the other party ("Receiving Party"), whether orally or in writing, that is designated as confidential. Confidential Information includes all information concerning: the Disclosing Party's customers and potential customers, past, present or proposed products, marketing plans, engineering and other designs, technical data, business plans, business opportunities, finances, research, development, and the terms and conditions of this Agreement. Confidential Information doesn't include any information that (i) is or becomes generally known to the public without breach of any obligation owed to the Disclosing Party, (ii) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party, (iii) is received from a third party without breach of any obligation owed to the Disclosing Party, or (iv) was independently developed by the Receiving Party. Subject to the foregoing exclusions, Customer Data will be considered Confidential Information under this Agreement regardless of whether or not it is designated as confidential.</p>
<p>"Contact" means a single individual (other than a User) whose Contact Information is stored by you in the Subscription Service.</p>
<p>"Contact Information" means the name, email address, phone number, online user name(s), telephone number, and similar information submitted by visitors to your landing pages on the Subscription Service or uploaded by you to the Subscription Service.</p>
<p>"Consulting Services" means the professional services provided to you by us, which may include training services, installation, integration or other consulting services.</p>
<p>"Customer Data" means all information that you submit or collect via the Subscription Service. Customer Data does not include KewMann Content.</p>
<p>"Customer Materials" means all materials that you provide or post, upload, input or submit for public display through the Subscription Service.</p>
<p>"Email Send Limit" means the number of emails that you may send in any given calendar month, as detailed in the Product Specific Terms.</p>
<p>&ldquo;Free Services&rdquo; means the Subscription Service or other products or features made available by us to you on an unpaid trial or free basis.</p>
<p>"KewMann Content" means all information, data, text, messages, software, sound, music, video, photographs, graphics, images, and tags that we incorporate into the Subscription Service or Consulting Services, including Enrichment Data (as defined in the Product Specific Terms).</p>
<p>&ldquo;Jurisdiction-Specific Terms&rdquo; means the additional terms that apply to your subscription, depending on your location. These terms form part of the Agreement and are available at&nbsp;<a href="https://legal.hubspot.com/jurisdiction-specific-terms"><strong>http://legal.KewMann.com/jurisdiction-specific-terms</strong></a>.</p>
<p>"Order" or "Order Form" means the KewMann-approved form or online subscription process by which you agree to subscribe to the Subscription Service and purchase Consulting Services.&nbsp;</p>
<p>&ldquo;Personal Data&rdquo; means any information relating to an identified or identifiable individual where such information is contained within Customer Data and is protected similarly as personal data or personally identifiable information under applicable Data Protection Laws (as defined in the DPA).</p>
<p>&ldquo;Product and Services Catalog&rdquo; means KewMann&rsquo;s Product and Services Catalog, as updated by us from time-to-time.</p>
<p>&ldquo;Product Specific Terms&rsquo; means the additional product-related terms that apply to your use of KewMann products, our consulting services and Third party Services.</p>
<p>"Sensitive Information" means credit or debit card numbers; financial account numbers or wire instructions; government issued identification numbers (such as Social Security numbers, passport numbers), biometric information, personal health information (or other information protected under any applicable health data protection laws), personal information of children protected under any child data protection laws, and any other information or combinations of information that falls within the definition of &ldquo;special categories of data&rdquo; under GDPR or any other applicable law relating to privacy and data protection.</p>
<p>"Subscription Fee" means the amount you pay for the Subscription Service.</p>
<p>"Subscription Service" means all of our web-based applications, tools and platforms that you have subscribed to under an Order Form or that we otherwise make available to you, and are developed, operated, and maintained by us, accessible via&nbsp;<a href="https://www.KewMann.com"><strong>https://www.KewMann.com</strong></a>&nbsp;or another designated URL, and any ancillary products and services, including website hosting, that we provide to you.</p>
<p>"Subscription Term" means the initial term of your subscription to the applicable Subscription Service, as specified on your Order Form(s), and each subsequent renewal term (if any). For Free Services, the Subscription Term will be the period during which you have an account to access the Free Services.</p>
<p>"Third-Party Products" means non-embedded products and professional services that are provided by third parties which interoperate with or are used in connection with the Subscription Service. These products and services include non-KewMann apps available from, for example, our marketplaces, directories, and links made available through the Subscription Service and non-KewMann services listed on services.KewMann.com.</p>
<p>"Third-Party Sites" means third-party websites linked to from within the Subscription Service, including Communications Services.</p>
<p>"Users" means your employees, representatives, consultants, contractors or agents who are authorized to use the Subscription Service for your benefit and have unique user identifications and passwords for the Subscription Service.</p>
<p>"KewMann", "we", "us" or &ldquo;our&rdquo; means the applicable contracting entity as specified in the &lsquo;Contracting Entity and Applicable Law&rsquo; section.</p>
<p>"You", "your" or &ldquo;Customer&rdquo; means the person or entity using the Subscription Service or receiving the Consulting Services and identified in the applicable account record, billing statement, online subscription process, or Order Form as the customer.</p>
<ol start="2">
  <li><strong> USE OF SERVICES</strong></li>
</ol>
<p>2.1&nbsp; Access.&nbsp; During the Subscription Term, we will provide your Users access to use the Subscription Service as described in this Agreement and the applicable Order. We may also provide your Users access to use our Free Services at any time by activating them in your KewMann account. We might provide some or all elements of the Subscription Service through third party service providers. You may provide access and use of the Subscription Service to your Affiliate's Users or allow them to receive the Consulting Services purchased under this Order; provided that, all such access, use and receipt by your Affiliate's Users is subject to and in compliance with the Agreement and you will at all times remain liable for your Affiliates' compliance with the Agreement.</p>
<p>2.2&nbsp; Additional Features. You may subscribe to additional features of the Subscription Service by placing an additional Order or activating the additional features from within your KewMann account (if this option is made available by us.). This Agreement will apply to all additional Order(s) and all additional features that you activate from within your KewMann account.</p>
<p>2.3&nbsp; Service Uptime Commitment. For details of KewMann&rsquo;s Service Uptime Commitment, please see the&nbsp;<a href="https://legal.hubspot.com/product-specific-terms"><strong>Product Specific Terms</strong></a>.&nbsp;</p>
<p>2.4&nbsp; Limits. The limits that apply to you will be specified in your Order Form, this Agreement or in our Product and Services Catalog, and for our Free Subscriptions, these limits may also be designated only from within the product itself. For further information on the limits that apply to your subscription, please refer to the&nbsp;<a href="https://legal.hubspot.com/product-specific-terms"><strong>Product Specific Terms</strong></a>.</p>
<p>You must be 18 years of age (or 20 years of age, if you are subject to the laws of Japan) or older to use the Subscription Service.</p>
<p>2.5&nbsp; Downgrades.&nbsp; Depending on your KewMann product, you may be entitled to downgrade your subscription. For further information on the downgrade terms that apply to your subscription, please refer to the&nbsp;<a href="https://legal.hubspot.com/product-specific-terms"><strong>Product Specific Terms</strong></a>.</p>
<p>2.6&nbsp; Modifications. We modify the Subscription Service from time to time, including by adding or deleting features and functions, in an effort to improve your experience. For further information on our modification rights that apply to your subscription, please refer to the&nbsp;<a href="https://legal.hubspot.com/product-specific-terms"><strong>Product Specific Terms</strong></a>.&nbsp;</p>
<p>2.7&nbsp; Customer Support. For information on the customer support terms that apply to your subscription, please refer to the&nbsp;<a href="https://legal.hubspot.com/product-specific-terms"><strong>Product Specific Terms</strong></a>.</p>
<p>2.8&nbsp; Acceptable Use. You will comply with our Acceptable Use Policy</p>
<p>2.9&nbsp; Prohibited and Unauthorized Use. You will not use the Subscription Service in any way that violates the terms of the AUP or for any purpose or in any manner that is unlawful or prohibited by this Agreement.</p>
<p>You may not use the Subscription Service if you are legally prohibited from receiving or using the Subscription Service under the laws of the country in which you are resident or from which you access or use the Subscription Service.&nbsp;</p>
<p>You will notify us right away of any unauthorized use of your Users&rsquo; identifications and passwords or your account</p>
<p>2.10&nbsp; No Sensitive Information. YOU ACKNOWLEDGE THAT THE SUBSCRIPTION SERVICES HAVE NOT BEEN DESIGNED TO PROCESS OR MANAGE SENSITIVE INFORMATION AND ACCORDINGLY YOU AGREE NOT TO USE THE SUBSCRIPTION SERVICE TO COLLECT, MANAGE OR PROCESS SENSITIVE INFORMATION. WE WILL NOT HAVE AND WE SPECIFICALLY DISCLAIM ANY LIABILITY THAT MAY RESULT FROM YOUR USE OF THE SUBSCRIPTION SERVICE TO COLLECT, PROCESS OR MANAGE SENSITIVE INFORMATION.</p>
<p>2.11&nbsp; Customer Responsibilities. To realize the full value of the Subscription Service and Consulting Services, your participation and effort are needed. Resources that may be required from you include a project manager, one or more content creators, a sales sponsor, an executive sponsor and a technical resource (or equivalent). Responsibilities that may be required include planning of marketing programs; setting of a content creation calendar; creating blog posts, social media content, Calls-To-Action (CTAs), downloads, emails, nurturing content, and other materials; acting as internal liaison between sales and marketing; providing top level internal goals for the use of the Subscription Service; attending regular success review meetings; and supporting the integration of the Subscription Service with other sales and marketing systems.</p>
<p>2.12&nbsp; Legacy Products. If you have a legacy KewMann product, some of the features and limits that apply to that product may be different than those that appear in these Master Terms, Product Specific Terms and/or the Product and Services Catalog. If you have legacy KewMann products, we may choose to move you to our then-current products at any time. If you determine that you are using a legacy product and would like to upgrade to a current-version, you must execute a new Order.&nbsp;</p>
<ol start="3">
  <li><strong> FEES</strong><br /><br /></li>
</ol>
<p>3.1&nbsp; Subscription Fees. The Subscription Fee will remain fixed during the initial term of your subscription unless (i) you exceed your Maximum Contacts, Email Send Limit, User or other applicable limits (see the &lsquo;Limits&rsquo; section above), (ii) you upgrade products or base packages, (iii) you subscribe to additional features or products, including additional Contacts, or (iv) otherwise agreed to in your Order. We may also choose to decrease your fees upon written notice to you. You can find all the information about how your fees may be otherwise adjusted in&nbsp;<a href="https://legal.hubspot.com/product-specific-terms"><strong>Product Specific Terms</strong></a>.&nbsp;</p>
<p>3.2&nbsp; Fee Adjustments at Renewal. Upon renewal, we may increase your fees up to our then-current list price set out in our Product and Services Catalog. If this increase applies to you, we will notify you at least thirty (30) days in advance of your renewal and the increased fees will apply at the start of the next renewal term. If you do not agree to this increase, either party can choose to terminate your subscription at the end of your then-current term by giving the notice required in the &lsquo;Notice of Non-Renewal&rsquo; section below.&nbsp;</p>
<p>3.3&nbsp; Payment against invoice. If you are paying by invoice, we will invoice you no more than thirty (30 days before the beginning of the Subscription Term and each subsequent Billing Period, and other times during the Subscription Term when fees are payable. All amounts invoiced are due and payable within thirty (30) days from the date of the invoice, unless otherwise specified in the Order Form.</p>
<p>3.5&nbsp; Payment Information. You will keep your contact information, billing information up to date. Changes may be made on your Billing Page within your KewMann account. All payment obligations are non-cancelable and all amounts paid are non-refundable, except as specifically provided for in this Agreement. All fees are due and payable in advance throughout the Subscription Term. If you are a KewMann Solutions Partner that purchases on behalf of a client, you agree to be responsible for the Order Form and to guarantee payment of all fees.</p>
<p>3.6&nbsp; Sales Tax. All fees are exclusive of taxes, which we will charge as applicable. You agree to pay any taxes applicable to your use of the Subscription Service and performance of Consulting Services. You shall have no liability for any taxes based upon our gross revenues or net income. If you are located in the European Union, all fees are exclusive of any VAT and you represent that you are registered for VAT purposes in your member state. At our request, you will provide us with the VAT registration number under which you are registered in your member state.&nbsp; If you do not provide us with a VAT registration number prior to your transaction being processed, we will not issue refunds or credits for any VAT that was charged. If you are subject to GST, all fees are exclusive of GST.</p>
<p>3.7&nbsp; Withholding Tax.&nbsp; If you are required to deduct or withhold tax from payment of your KewMann invoice, you may deduct this amount from the applicable Subscription Fee due to the extent it is due and payable as assessed withholding tax required under laws that apply to you (the &ldquo;Deduction Amount&rdquo;).&nbsp;&nbsp;</p>
<p>You will not be required to repay the Deduction Amount to us, provided that you present us with a valid tax receipt verifying payment of the Deduction Amount to the relevant tax authority within ninety (90) days from the date of the invoice. If you do not provide this tax receipt within the specified time period, then all fees, inclusive of the Deduction Amount, will be immediately due and payable, and failure to pay these fees may result in your account being suspended or terminated for non-payment.&nbsp;</p>
<ol start="4">
  <li><strong> TERM AND TERMINATION</strong><br /><br /></li>
</ol>
<p>4.1&nbsp; Term and Renewal. Your initial subscription period will be specified in your Order, and, unless otherwise specified in your Order, your subscription will automatically renew for the shorter of the subscription period, or one year.&nbsp;</p>
<p>4.2&nbsp; Notice of Non-Renewal.&nbsp; Unless otherwise specified in your Order, to prevent renewal of your subscription, you or we must give written notice of non-renewal. The deadline for sending this notice varies depending on the KewMann product and edition you have subscribed to. For more information on non-renewal notice periods, please see the&nbsp;<a href="https://legal.hubspot.com/product-specific-terms"><strong>Product Specific Terms</strong></a>.&nbsp;</p>
<p>If you decide not to renew, you may send this non-renewal notice to us by indicating that you do not want to renew by turning auto-renew off by accessing the billing details information in your KewMann account, or by following the steps&nbsp;<a href="https://knowledge.hubspot.com/account/how-do-i-cancel-my-hubspot-account"><strong>here</strong></a>, as applicable.</p>
<p>4.3&nbsp; Early Cancellation.&nbsp; You may choose to cancel your subscription early at your convenience provided that, we will not provide any refunds of prepaid fees or unused Subscription Fees, and you will promptly pay all unpaid fees due through the end of the Subscription Term. See the 'Notice of Non-Renewal' section for information on how to cancel your subscription.</p>
<p>4.4&nbsp; Termination for Cause. Either party may terminate this Agreement for cause, as to any or all Subscription Services: (i) upon thirty (30) days&rsquo; notice to the other party of a material breach if such breach remains uncured at the expiration of such period, or (ii) immediately, if the other party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, cessation of business, liquidation or assignment for the benefit of creditors.&nbsp;</p>
<p>We may also terminate this Agreement for cause on thirty (30) days&rsquo; notice if we determine that you are acting, or have acted, in a way that has or may negatively reflect on or affect us, our prospects, or our customers.&nbsp;</p>
<p>This Agreement may not otherwise be terminated prior to the end of the Subscription Term.</p>
<p>4.5&nbsp; Suspension</p>
<p>4.5.1&nbsp; Suspension for Prohibited Acts</p>
<p>We may suspend any User&rsquo;s access to any or all Subscription Services without notice for:&nbsp;</p>
<p>(i) use of the Subscription Service in a way that violates applicable local, state, federal, or foreign laws or regulations or the terms of this Agreement,&nbsp;</p>
<p>(ii) use of the KewMann email send service that results in excessive hard bounces, SPAM complaints via feedback loops, direct spam complaints (to our abuse desk), or requests for removal from a mailing list by recipients, or&nbsp;</p>
<p>(iii) repeated instances of posting or uploading material that infringes or is alleged to infringe on the copyright or trademark rights of any person or entity.&nbsp;&nbsp;&nbsp;</p>
<p>We may, without notice, review and delete any Customer Data or Customer Materials that we determine in good faith violate these terms or the AUP, provided that, we have no duty (unless applicable laws or regulations provide otherwise) to prescreen, control, monitor or edit your Customer Data or Customer Materials.</p>
<p>4.5.2&nbsp; Suspension for Non-Payment</p>
<p>We will provide you with notice of non-payment of any amount due. Unless the full amount has been paid, we may suspend your access to any or all of the Subscription Services ten (10) days after such notice. We will not suspend the Subscription Service while you are disputing the applicable charges reasonably and in good faith and are cooperating diligently to resolve the dispute. If a Subscription Service is suspended for non-payment, we may charge a re-activation fee to reinstate the Subscription Service.</p>
<p>4.5.3&nbsp; Suspension for Present Harm</p>
<p>If your website, or use of, the Subscription Service:&nbsp;</p>
<p>(i) is being subjected to denial of service attacks or other disruptive activity,&nbsp;</p>
<p>(ii) is being used to engage in denial of service attacks or other disruptive activity,&nbsp;</p>
<p>(iii) is creating a security vulnerability for the Subscription Service or others,&nbsp;</p>
<p>(iv) is consuming excessive bandwidth, or&nbsp;</p>
<p>(v) is causing harm to us or others, then we may, with electronic or telephonic notice to you, suspend all or any access to the Subscription Service.&nbsp;</p>
<p>We will try to limit the suspension to the affected portion of the Subscription Service and promptly resolve the issues causing the suspension of the Subscription Service. Nothing in this clause limits our right to terminate for cause as outlined above, if we determine that you are acting, or have acted, in a way that has or may negatively reflect on or affect us, our prospects, or our customers.</p>
<p>4.5.4&nbsp; Suspension and Termination of Free Services</p>
<p>We may suspend, limit, or terminate the Free Services for any reason at any time without notice. We may terminate your subscription to the Free Services due to your inactivity.</p>
<p>4.6 Effect of Termination or Expiration. &nbsp; If your paid subscription is terminated or expires, we will continue to make available to you our Free Services provided however, this may not be the case if your Agreement was terminated for cause.&nbsp;</p>
<p>You will continue to be subject to this Agreement for as long as you have access to a KewMann account.</p>
<p>Upon termination or expiration of this Agreement, you will stop all use of the Subscription Service and KewMann Content. If you terminate this Agreement for cause, we will promptly refund any prepaid but unused fees covering use of the Subscription Service after termination. If we terminate this Agreement for cause, you will promptly pay all unpaid fees due through the end of the Subscription Term. Fees are otherwise non-refundable.</p>
<ol start="5">
  <li><strong> CUSTOMER DATA</strong></li>
</ol>
<p>5.1&nbsp; Customer&rsquo;s Proprietary Rights. You own and retain all rights to the Customer Materials and Customer Data. This Agreement does not grant us any ownership rights to Customer Materials or Customer Data. You grant permission to us and our licensors to use the Customer Materials and Customer Data only as necessary to provide the Subscription Service and Consulting Services to you and as otherwise permitted by this Agreement. If you are using the Subscription Service or receiving Consulting Services on behalf of another party, then you represent and warrant that you have all sufficient and necessary rights and permissions to do so.</p>
<p>5.2&nbsp; Limits on KewMann. We will not use, or allow anyone else to use, Customer Data to contact any individual or company except as you direct or otherwise permit. We will use Customer Data only in order to provide the Subscription Service and Consulting Services to you and only as permitted by applicable law and this Agreement.&nbsp;</p>
<p>5.3&nbsp; Data Practices and Machine Learning.&nbsp; We may monitor use of the Subscription Service by all of our customers and use the information gathered in an aggregate and anonymized manner. We may use Customer Data in an anonymized manner for machine learning. For more information on these practices please see the &lsquo;Data Practices and Machine Learning&rsquo; section of our Product Specific Terms.</p>
<p>5.4 Protection of Customer Data. The terms of the DPA are hereby incorporated by reference and will apply to the extent any Customer Data includes Personal Data. The DPA sets out how we will process Personal Data on your behalf in connection with the Subscription Services provided to you under this Agreement. We will maintain commercially appropriate administrative, physical, and technical safeguards to protect Personal Data as described in the DPA, including our security measures in Annex 2 of our DPA.</p>
<p>5.5&nbsp; Retention, Deletion and Retrieval of Customer Data. For information on our procedures regarding the retention and deletion of Customer Data, please see the &lsquo;KewMann Obligations&rsquo; section of our DPA. You can learn more about your right to retrieve Customer Data from your KewMann account in the &lsquo;Retrieval of Customer Data&rsquo; sections as specified in our&nbsp;<a href="https://legal.hubspot.com/product-specific-terms"><strong>Product Specific Terms</strong></a>.</p>
<ol start="6">
  <li><strong> INTELLECTUAL PROPERTY</strong></li>
</ol>
<p>6.1&nbsp; This is an agreement for access to and use of the Subscription Service, and you are not granted a license to any software by this Agreement. The Subscription Service and Consulting Services are protected by intellectual property laws, they belong to and are the property of us or our licensors (if any), and we retain all ownership rights to them. You agree not to copy, rent, lease, sell, distribute, or create derivative works based on the KewMann Content, the Subscription Service, or the Consulting Services in whole or in part, by any means, except as expressly authorized in writing by us. Our trademarks include, but aren&rsquo;t limited to, those listed at https://www.KewMann.com (which we may update at any time without notice to you) and you may not use any of these without our prior written permission.</p>
<p>6.2&nbsp; We encourage all customers to comment on the Subscription Service or Consulting Services, provide suggestions for improving it, and vote on suggestions they like. You agree that all such comments and suggestions will be non-confidential and that we own all rights to use and incorporate them into the Subscription Service or Consulting Services, without payment or attribution to you.</p>
<ol start="7">
  <li><strong> CONFIDENTIALITY</strong></li>
</ol>
<p>7.1 The Receiving Party will: (i) protect the confidentiality of the Confidential Information of the Disclosing Party using the same degree of care that it uses to protect the confidentiality of its own confidential information of like kind, but in no event less than reasonable care, (ii) not use any Confidential Information of the Disclosing Party for any purpose outside the scope of this Agreement, (iii) not disclose Confidential Information of the Disclosing Party to any third party (except those third party service providers used by us to provide some or all elements of the Subscription Service or Consulting Services and except for your KewMann Solutions Partner bound by confidentiality obligations), and (iv) limit access to Confidential Information of the Disclosing Party to those of its and its affiliates' employees, contractors and agents who need such access for purposes consistent with this Agreement and who have signed confidentiality agreements with the Receiving Party containing protections no less stringent than those herein.</p>
<p>7.2&nbsp; The Receiving Party may disclose Confidential Information of the Disclosing Party if required to do so under any federal, state, or local law, statute, rule or regulation, subpoena or legal process; provided, however, that (i) the Receiving Party will provide the Disclosing Party with prompt notice of any request that it disclose Confidential Information, sufficient to allow the Disclosing Party to object to the request and/or seek an appropriate protective order or, if such notice is prohibited by law, the Receiving Party will disclose the minimum amount of Confidential Information required to be disclosed under the applicable legal mandate; and (ii) in no event will the Receiving Party disclose Confidential Information to a party other than a government agency except under a valid order from a court having jurisdiction requiring the specific disclosure.</p>
<ol start="8">
  <li><strong> PUBLICITY</strong></li>
</ol>
<p>You grant us the right to add your name and company logo to our customer list and website. You can opt-out of this use by notifying us.</p>
<p>&nbsp;</p>
<ol start="9">
  <li><strong> INDEMNIFICATION</strong></li>
</ol>
<p>You will indemnify, defend and hold us and our Affiliates harmless, at your expense, against any third-party claim, suit, action, or proceeding (each, an "Action") brought against us (and our officers, directors, employees, agents, service providers, licensors, and affiliates) by a third party not affiliated with us or our Affiliates to the extent that such Action is based upon or arises out of&nbsp;</p>
<p>(a) unauthorized or illegal use of the Subscription Service by you or your Affiliates,&nbsp;</p>
<p>(b) your or your Affiliates' noncompliance with or breach of this Agreement,&nbsp;</p>
<p>(c) your or your Affiliates' use of Third-Party Products, or&nbsp;</p>
<p>(d) the unauthorized use of the Subscription Service by any other person using your User information.&nbsp;</p>
<p>We will: notify you in writing within thirty (30) days of our becoming aware of any such claim; give you sole control of the defense or settlement of such a claim; and provide you (at your expense) with any and all information and assistance reasonably requested by you to handle the defense or settlement of the claim. You will not accept any settlement that (i) imposes an obligation on us; (ii) requires us to make an admission; or (iii) imposes liability not covered by these indemnifications or places restrictions on us without our prior written consent.</p>
<ol start="10">
  <li><strong> DISCLAIMERS; LIMITATION OF LIABILITY</strong></li>
</ol>
<p>10.1&nbsp; Disclaimer of Warranties. WITHOUT LIMITING OUR OBLIGATIONS IN THE 'PROTECTION OF CUSTOMER DATA' SECTION OF THIS AGREEMENT, WE AND OUR AFFILIATES AND AGENTS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, SECURITY OR ACCURACY OF THE SUBSCRIPTION SERVICE, DATA MADE AVAILABLE FROM THE SUBSCRIPTION SERVICE, KEWMANN CONTENT, OR THE CONSULTING SERVICES FOR ANY PURPOSE. APPLICATION PROGRAMMING INTERFACES (APIs) MAY NOT BE AVAILABLE AT ALL TIMES. TO THE EXTENT PERMITTED BY LAW, THE SUBSCRIPTION SERVICE, KEWMANN CONTENT AND CONSULTING SERVICES ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. WE DISCLAIM ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, WITH REGARD TO THE SUBSCRIPTION SERVICE AND THE CONSULTING SERVICES, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>
<p>10.2&nbsp; No Indirect Damages. TO THE EXTENT PERMITTED BY LAW, IN NO EVENT WILL EITHER PARTY OR ITS AFFILIATES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, OR LOSS OF PROFITS, REVENUE, DATA OR BUSINESS OPPORTUNITIES ARISING OUT OF OR RELATED TO THIS AGREEMENT, WHETHER AN ACTION IS IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF LIABILITY; PROVIDED THAT, THIS LIMITATION WILL NOT APPLY TO YOU IF YOU ONLY USE THE FREE SERVICES.</p>
<p>10.3&nbsp; Limitation of Liability. EXCEPT FOR YOUR LIABILITY FOR PAYMENT OF FEES, YOUR LIABILITY ARISING FROM YOUR OBLIGATIONS UNDER THE &lsquo;INDEMNIFICATION&rsquo; SECTION, AND YOUR LIABILITY FOR VIOLATION OF OUR INTELLECTUAL PROPERTY RIGHTS, IF, NOTWITHSTANDING THE OTHER TERMS OF THIS AGREEMENT, EITHER PARTY OR ITS AFFILIATES IS DETERMINED TO HAVE ANY LIABILITY TO THE OTHER PARTY, ITS AFFILIATES OR ANY THIRD PARTY, THE PARTIES AGREE THAT THE AGGREGATE LIABILITY OF A PARTY AND ITS AFFILIATES WILL BE LIMITED TO A SUM EQUAL TO THE TOTAL AMOUNTS PAID OR PAYABLE FOR THE SUBSCRIPTION SERVICE IN THE TWELVE MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO A CLAIM; PROVIDED HOWEVER, THIS LIMITATION WILL NOT APPLY TO YOU IF YOU ONLY USE THE FREE SERVICES, AND IN THIS CASE, IF WE ARE DETERMINED TO HAVE ANY LIABILITY TO YOU OR ANY THIRD PARTY ARISING FROM YOUR USE OF THE FREE SERVICES, THEN OUR AGGREGATE LIABILITY WILL BE LIMITED TO ONE HUNDRED U.S. DOLLARS.&nbsp;</p>
<p>10.4&nbsp; Third Party Products. WE AND OUR AFFILIATES DISCLAIM ALL LIABILITY WITH RESPECT TO THIRD-PARTY PRODUCTS THAT YOU USE. OUR LICENSORS WILL HAVE NO LIABILITY OF ANY KIND UNDER THIS AGREEMENT.</p>
<p>10.5&nbsp; Agreement to Liability Limit. YOU UNDERSTAND AND AGREE THAT ABSENT YOUR AGREEMENT TO THIS LIMITATION OF LIABILITY, WE WOULD NOT PROVIDE THE SUBSCRIPTION SERVICE TO YOU.</p>
<ol start="11">
  <li><strong> MISCELLANEOUS</strong></li>
</ol>
<p>11.1&nbsp; Amendment; No Waiver.</p>
<p>We may modify any part or all of the Agreement by posting a revised version at&nbsp;<a href="https://www.KewMann.com"><strong>https://www.KewMann.com</strong></a>.&nbsp; The revised version will become effective and binding the next business day after it is posted. We will provide you notice of this revision by email or in-app notification</p>
<p>If you do not agree with a modification to the Agreement, you must notify us in writing within thirty (30) days after we send notice of the revision. If you give us this notice, then your subscription will continue to be governed by the terms and conditions of the Agreement prior to modification until your next renewal date, after which the current terms posted at&nbsp;<a href="https://www.KewMann.com"><strong>https://www.KewMann.com</strong></a>&nbsp;will apply.&nbsp;&nbsp;However, if we can no longer reasonably provide the subscription to you under the terms prior to modification (for example, if the modifications are required by law or result from general product changes), then the Agreement and/or affected Subscription Services will terminate upon our notice to you and we will promptly refund any prepaid but unused fees covering use of the Subscription Service after termination.&nbsp;</p>
<p>No delay in exercising any right or remedy or failure to object will be a waiver of such right or remedy or any other right or remedy. A waiver on one occasion will not be a waiver of any right or remedy on any future occasion.</p>
<p>11.2&nbsp; Force Majeure. Neither party will be responsible for failure or delay of performance if caused by: an act of war, hostility, or sabotage; act of God; electrical, internet, or telecommunication outage that is not caused by the obligated party; government restrictions; or other event outside the reasonable control of the obligated party. Each party will use reasonable efforts to mitigate the effect of a force majeure event.</p>
<p>11.3&nbsp; Actions Permitted. Except for actions for nonpayment or breach of a party&rsquo;s proprietary rights, no action, regardless of form, arising out of or relating to this Agreement may be brought by either party more than one (1) year after the cause of action has accrued.</p>
<p>11.4&nbsp; Relationship of the Parties. You and we agree that no joint venture, partnership, employment, or agency relationship exists between us.<br /><br /></p>
<p>11.5&nbsp; Compliance with Laws. We will comply with all Singapore and Malaysia laws(where applicable) in our provision of the Subscription Service, the Consulting Services and our processing of Customer Data. We reserve the right at all times to disclose any information as necessary to satisfy any law, regulation, legal process or governmental request.&nbsp;</p>
<p>We will comply with our Code of Business Conduct and Ethics (a/k/a our Code of Use Good Judgment). You will comply with all laws in your use of the Subscription Service and Consulting Services, including any applicable export laws.&nbsp;&nbsp;</p>
<p>You must comply with all applicable laws related to the recording of phone calls and ensure all proper consent to record is obtained prior to making any such recording</p>
<p>You will not directly or indirectly export, re-export, or transfer the Subscription Service or Consulting Services to prohibited countries or individuals or permit use of the Subscription Service or Consulting Services by prohibited countries or individuals.</p>
<p>11.6 Severability. If any part of this Agreement or an Order Form is determined to be invalid or unenforceable by applicable law, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of this Agreement will continue in effect.</p>
<p>11.7&nbsp; Notices. To KewMann: Notice will be sent to the contact address set forth, and will be deemed delivered as of the date of actual receipt.</p>
<p>To you: your address as provided in our KewMann Subscription account information for you. We may give electronic notices by general notice via the Subscription Service and may give electronic notices specific to you by email to your e-mail address(es) on record in our account information for you or through the notifications center of the Subscription Service. We may give notice to you by telephone calls to the telephone numbers on record in our account information for you. You must keep all of your account information current.</p>
<p>11.8&nbsp; Entire Agreement. This Agreement (including each Order), along with our Privacy Policy,&nbsp;are the entire agreement between us for the Subscription Service and Consulting Services and supersedes all other proposals and agreements, whether electronic, oral or written, between us. We object to and reject any additional or different terms proposed by you, including those contained in your purchase order, acceptance or website. Our obligations are not contingent on the delivery of any future functionality or features of the Subscription Service or dependent on any oral or written public comments made by us regarding future functionality or features of the Subscription Service. We might make versions of this Agreement available in languages other than English.&nbsp; If we do, the English version of this Agreement will govern our relationship and the translated version is provided for convenience only and will not be interpreted to modify the English version of this Agreement.</p>
<p>11.9&nbsp; Assignment. You will not assign or transfer this Agreement without our prior written consent, except that you may assign this Agreement to a successor by reason of merger, reorganization, sale of all or substantially all of your assets, change of control or operation of law, provided such successor is not a competitor of ours. We may assign this Agreement to any KewMann affiliate or in the event of merger, reorganization, sale of all or substantially all of our assets, change of control or operation of law.</p>
<p>11.10&nbsp; No Third Party Beneficiaries. Nothing in this Agreement, express or implied, is intended to or will confer upon any third party person or entity any right, benefit or remedy of any nature whatsoever under or by reason of this Agreement.</p>
<p>11.11&nbsp; Contract for Services. This Agreement is a contract for the provision of services and not a contract for the sale of goods, the parties agree that the United Nations Convention on Contracts for the International Sale of Goods will not govern this Agreement or the rights and obligations of the parties under this Agreement.</p>
<p>11.12&nbsp; Authority. Each party represents and warrants to the other that it has full power and authority to enter into this Agreement and that it is binding upon such party and enforceable in accordance with its terms. You further warrant and represent that you have the authority to procure your Affiliates compliance with the terms of this Agreement.</p>
<p>11.13&nbsp; Survival. The following sections will survive the expiration or termination of this Agreement: 'Definitions&rsquo;, &lsquo;Fees&rsquo;, 'Prohibited and Unauthorized Use', &lsquo;Early Cancellation', &lsquo;Termination for Cause&rsquo;, &lsquo;Suspension for Prohibited Acts&rsquo;, &lsquo;Suspension for Non-Payment&rsquo;, &lsquo;Suspension for Present Harm&rsquo;, &lsquo;Suspension and Termination of Free Services&rsquo;, &lsquo;Effect of Termination or Expiration&rsquo;, &lsquo;Intellectual Property&rsquo;, &lsquo;Customer&rsquo;s Proprietary Rights&rsquo;, 'Confidentiality&rsquo;, &lsquo;Publicity&rsquo;, &lsquo;Indemnification&rsquo;, &lsquo;Disclaimers; Limitations of Liability&rsquo;, &lsquo;Miscellaneous&rsquo; and &lsquo;Contracting Entity and Applicable Law&rsquo;.&nbsp; Additionally, the &lsquo;Retrieval of Customer Data&rsquo; sections and the &lsquo;Alpha/Beta Services&rsquo; section of the Product Specific Terms page will survive expiration or termination of this Agreement.</p>
<p>11.14&nbsp; Precedence. In the event of a conflict between the terms of the Agreement and an Order, the terms of the Order shall control, but only as to that Order.</p>
<p><strong>APPENDIX 1</strong></p>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button mat-dialog-close cdkFocusInitial>Accept</button>
</mat-dialog-actions>
