<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div
  class="main-container"
  [dir]="dir"
  [ngClass]="{
    minisidebar: minisidebar,
    boxed: boxed,
    danger: danger,
    blue: blue,
    green: green,
    dark: dark
  }"
>
<!--  &lt;!&ndash; ============================================================== &ndash;&gt;-->
<!--  &lt;!&ndash; Topbar - style you can find in header.scss &ndash;&gt;-->
<!--  &lt;!&ndash; ============================================================== &ndash;&gt;-->
<!--  <mat-toolbar color="primary" class="topbar telative">-->
<!--    &lt;!&ndash; ============================================================== &ndash;&gt;-->
<!--    &lt;!&ndash; Logo - style you can find in header.scss &ndash;&gt;-->
<!--    &lt;!&ndash; ============================================================== &ndash;&gt;-->
<!--    <div class="navbar-header">-->
<!--      <a class="navbar-brand" href="/">-->
<!--        &lt;!&ndash; Logo text &ndash;&gt;-->
<!--        <b>-->
<!--          &lt;!&ndash; dark Logo text &ndash;&gt;-->
<!--          <img-->
<!--            src="assets/images/logo-light.png"-->
<!--            alt="homepage"-->
<!--            class="dark-logo"-->
<!--          />-->
<!--          &lt;!&ndash; Light Logo text &ndash;&gt;-->
<!--          <img-->
<!--            src="assets/images/logo-light.png"-->
<!--            class="light-logo nav-logo"-->
<!--            alt="homepage"-->
<!--          />-->
<!--        </b>-->
<!--      </a>-->
<!--      &lt;!&ndash; ============================================================== &ndash;&gt;-->
<!--      &lt;!&ndash; sidebar toggle &ndash;&gt;-->
<!--      &lt;!&ndash; ============================================================== &ndash;&gt;-->
<!--      <button mat-icon-button fxHide fxShow.gt-sm (click)="toggleMini()">-->
<!--        <mat-icon>menu</mat-icon>-->
<!--      </button>-->
<!--      <button-->
<!--        mat-icon-button-->
<!--        fxShow-->
<!--        fxHide.gt-sm-->
<!--        (click)="snav.toggle()"-->
<!--        value="sidebarclosed"-->
<!--      >-->
<!--        <mat-icon>menu</mat-icon>-->
<!--      </button>-->
<!--    </div>-->
<!--    <span fxFlex></span>-->
<!--    &lt;!&ndash; ============================================================== &ndash;&gt;-->
<!--    &lt;!&ndash; app header component - style you can find in header.scss / header.component.ts&ndash;&gt;-->
<!--    &lt;!&ndash; ============================================================== &ndash;&gt;-->
<!--    <app-header></app-header>-->
<!--    &lt;!&ndash; ============================================================== &ndash;&gt;-->
<!--    &lt;!&ndash; Right sidebar toggle - style you can find in rightsidebar.component.scss &ndash;&gt;-->
<!--    &lt;!&ndash; ============================================================== &ndash;&gt;-->
<!--    &lt;!&ndash;<button (click)="end.toggle()" mat-icon-button class="ml-xs overflow-visible">&ndash;&gt;-->
<!--    &lt;!&ndash;    <mat-icon>settings</mat-icon>&ndash;&gt;-->
<!--    &lt;!&ndash;</button>&ndash;&gt;-->
<!--  </mat-toolbar>-->
<!--  &lt;!&ndash; ============================================================== &ndash;&gt;-->
<!--  &lt;!&ndash; End Topbar - style you can find in pages.scss &ndash;&gt;-->
<!--  &lt;!&ndash; ============================================================== &ndash;&gt;-->
  <mat-sidenav-container
    class="example-sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
    [ngClass]="{ minisidebar: minisidebar }"
  >
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav
      #snav
      id="snav"
      class="pl-xs"
      [mode]="mobileQuery.matches ? 'side' : 'over'"
      fixedTopGap="0"
      [opened]="mobileQuery.matches"
      [disableClose]="mobileQuery.matches"
      (open)="sidebarOpened = true"
      (close)="sidebarOpened = false"
      [perfectScrollbar]="config"
    >
      <app-sidebar class="app-sidebar" [minisidebar]="minisidebar" (sidebarStatus)="toggleMini()"></app-sidebar>
    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
    <!-- ============================================================== -->
    <!-- <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
        <div class="scroll">
            <mat-nav-list>

                <h3 mat-subheader class="text-uppercase font-weight-bold">Settings</h3>
                <mat-list-item>
                    <mat-slide-toggle color="warn" (change)="dir = (dir == 'rtl' ? 'ltr' : 'rtl')">RTL</mat-slide-toggle>
                </mat-list-item>
                <mat-list-item>
                    <mat-slide-toggle color="warn" [(ngModel)]="boxed">Boxed Layout</mat-slide-toggle>
                </mat-list-item>
                <mat-list-item>
                <mat-slide-toggle [(ngModel)]="minisidebar">Mini Sidebar</mat-slide-toggle>
              </mat-list-item>
                <mat-divider></mat-divider>
                <h3 mat-subheader class="text-uppercase font-weight-bold">Colors</h3>
                <mat-list-item>
                    <mat-checkbox color="warn" [(ngModel)]="danger" (change)="green = blue = false" class="text-danger">Red</mat-checkbox>
                </mat-list-item>
                <mat-list-item>
                    <mat-checkbox color="warn" [(ngModel)]="green" (change)="danger = blue = false" class="text-megna">Teal
                        Green</mat-checkbox>
                </mat-list-item>
                <mat-list-item>
                    <mat-checkbox color="warn" [(ngModel)]="blue" (change)="green = danger = false" class="text-info">Blue</mat-checkbox>
                </mat-list-item>
                <mat-list-item>
                    <mat-checkbox color="warn" [(ngModel)]="dark" (change)="green = blue = danger = false">Dark</mat-checkbox>
                </mat-list-item>
            </mat-nav-list>
        </div>
    </mat-sidenav> -->

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content
      class="page-wrapper"
      [ngStyle]="{ 'margin-left.px': contentMargin }"
    >

      <div class="mini-toolbar bg-white" fxShow
           fxHide.gt-sm>
        <button
          mat-icon-button
          fxShow
          fxHide.gt-sm
          (click)="snav.toggle()"
          value="sidebarclosed"
        >
          <mat-icon>menu</mat-icon>
        </button>
        <mat-slide-toggle class="pull-right p-10">Dark mode</mat-slide-toggle>
      </div>
      <!--<xng-breadcrumb separator=">"></xng-breadcrumb>-->
      <div class="page-content">

        <router-outlet>
          <app-spinner></app-spinner>
        </router-outlet>
      </div>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>
