import { Injectable } from "@angular/core";
import { APP_CONFIG } from "../cores/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import User from "../models/User";
import UserListRequest from "../models/UserListRequest";
import PageOptions from "../models/PageOptions";
import UserActivitiesFilter from "../models/UserActivitiesFilter";
import UserRole from "../models/UserRole";
import UserNotificationFilter from "../models/UserNotificationFilter";

@Injectable()
export class UserService {
  private readonly kaiEndpoint: string;
  private readonly socketEndpoint: string;
  private httpOptions: any;

  constructor(private httpService: HttpClient) {
    const {
      socketEndpoint,
      endpoint: apiEndpoint,
      prefix: { user: userPrefix },
    } = APP_CONFIG.secrets.api;
    this.kaiEndpoint = `${apiEndpoint}${userPrefix}`;
    this.socketEndpoint = socketEndpoint.replace("ws", "");
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };
  }

  getList(params: UserListRequest) {
    return this.httpService.get(
      `${this.kaiEndpoint}user/all?page=${params.page}&size=${params.size}&status=${params.status}&query=${params.query}`
    );
  }

  getUser(id: string) {
    return this.httpService.get(`${this.kaiEndpoint}user/${id}`);
  }

  editUser(user: User) {
    return this.httpService.put(`${this.kaiEndpoint}user`, user);
  }

  updateStatus(userId: string, status: string) {
    const params = new URLSearchParams({
      userStatus: status,
    });
    return this.httpService.put(
      `${this.kaiEndpoint}user/status/${userId}?${params.toString()}`,
      {},
      this.httpOptions
    );
  }

  addUser(user: User) {
    return this.httpService.post(`${this.kaiEndpoint}user`, user);
  }

  deleteUser(id: string) {
    return this.httpService.delete(`${this.kaiEndpoint}user/${id}`);
  }

  changeCover(cover: string) {
    return this.httpService.put(`${this.kaiEndpoint}user/cover`, {
      cover_picture: cover,
    });
  }

  updateProfile(user: User) {
    return this.httpService.put(`${this.kaiEndpoint}user/profile`, user);
  }

  changePasswords(password) {
    return this.httpService.put(
      `${this.kaiEndpoint}user/password?currentPassword=${password.current}&newPassword=${password.new}`,
      ""
    );
  }

  getUserProfile() {
    return this.httpService.get(`${this.kaiEndpoint}profile`);
  }

  getActivitiesTimeline(range: string) {
    return this.httpService.get(
      `${this.kaiEndpoint}activity/timeline?activity_timeline=${range}`
    );
  }

  getActivitiesTimelineByUserId(id: string, range: string) {
    return this.httpService.get(
      `${this.kaiEndpoint}activity/timeline/${id}?activity_timeline=${range}`
    );
  }

  getActivitiesPaging(po: UserActivitiesFilter) {
    return this.httpService.get(
      `${this.kaiEndpoint}audit?page=${po.currentPage}&size=${po.limit}&month=${po.month}&year=${po.year}`
    );
  }

  getActivitiesPagingByUserId(userId: string, po: UserActivitiesFilter) {
    return this.httpService.get(
      `${this.kaiEndpoint}audit/${userId}?page=${po.currentPage}&size=${po.limit}&month=${po.month}&year=${po.year}`
    );
  }

  getNotifications(id: string) {
    return this.httpService.get(`${this.kaiEndpoint}user/${id}`);
  }

  getNotificationsPaging(userId: string, unf: UserNotificationFilter) {
    return this.httpService.get(
      `${this.kaiEndpoint}notification/view/all/ranged/monthly?page=${unf.currentPage}&size=${unf.limit}&month=${unf.month}&year=${unf.year}`
    );
  }

  getSuccessLogin(id: string) {
    return this.httpService.get(
      `${this.kaiEndpoint}user/statistics/login/${id}?status=SUCCESS`
    );
  }

  getFailedLogin(id: string) {
    return this.httpService.get(
      `${this.kaiEndpoint}user/statistics/login/${id}?status=FAILURE`
    );
  }

  editUserRole(ur: UserRole[], userId: string) {
    return this.httpService.put(
      `${this.kaiEndpoint}user/role/edit/${userId}`,
      ur
    );
  }
}
