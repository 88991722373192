import { Injectable } from "@angular/core";
import { APP_CONFIG } from "../cores/config";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import PageOptions from "../models/PageOptions";
import Schema from "../models/Schema";
import Role from "../models/Role";

@Injectable()
export class RoleAccessService {
  // private readonly kaiEndpoint: string;
  private readonly ksEndpoint: string;

  constructor(private httpService: HttpClient) {
    const {
      // endpoint: apiEndpoint,
      kdEndpoint: ksApiEndpoint,
      prefix: { authorization: authorizationPrefix },
    } = APP_CONFIG.secrets.api;
    // this.kaiEndpoint = `${apiEndpoint}${authorizationPrefix}`;
    this.ksEndpoint = `${ksApiEndpoint}${authorizationPrefix}`;
  }

  getListRole(pageOptions: PageOptions) {
    // authorization/api/v1/authorization/roles?page=1&size=5
    return this.httpService.get<Role>(
      `${this.ksEndpoint}authorization/roles?page=${pageOptions.currentPage}&size=${pageOptions.limit}&search_keyword=${pageOptions.keyword}`
    );
  }

  getListRules() {
    return this.httpService.get<Schema>(
      `${this.ksEndpoint}authorization/rules?page=1&size=100`
    );
  }

  addRole(role: Role) {
    return this.httpService.post(`${this.ksEndpoint}authorization/role`, role);
  }

  editRole(role: Role) {
    return this.httpService.put(`${this.ksEndpoint}authorization/role`, role);
  }

  deleteRole(role: Role) {
    return this.httpService.delete(
      `${this.ksEndpoint}authorization/role/${role._object_id}`
    );
  }

  getRole(roleId: string) {
    return this.httpService.get(
      `${this.ksEndpoint}authorization/role/${roleId}`
    );
  }

  getUserAccess(userId: string) {
    return this.httpService.get(
      `${this.ksEndpoint}authorization/user/rule/${userId}`
    );
  }

  // addData(tableId: string, data: any) {
  // 	return this.httpService.post(
  // 		`${this.kaiEndpoint}${this.data}?tableID=${tableId}`,
  // 		data
  // 	);
  // }

  // editData(tableId: string, fieldId: string, data: any) {
  // 	return this.httpService.put(
  // 		`${this.kaiEndpoint}${this.data}/${tableId}/${fieldId}/`,
  // 		data
  // 	);
  // }

  // deleteData(tableId: string, fieldIds: string[]) {
  // 	const headers = new HttpHeaders({
  // 		"Content-Type": "application/json"
  // 	});
  // 	return this.httpService.request(
  // 		"DELETE",
  // 		`${this.kaiEndpoint}${this.data}/${tableId}/`,
  // 		{
  // 			body: fieldIds,
  // 			headers: headers
  // 		}
  // 	);
  // }

  // group(tableId: string, payload: GroupColumnRequest): Observable<any> {
  // 	return this.httpService.post(
  // 		`${this.kaiEndpoint}${this.data}/dashboard/group/field?tableID=${tableId}`,
  // 		payload
  // 	);
  // }
}
